define("client/react/components/alt/form-gl-autocomplete", ["exports", "client/react/utilities/use-form", "client/react/utilities/autocomplete", "@mui/material", "react"], function (_exports, _useForm, _autocomplete, _material, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _excluded = ["formProps", "renderInputParams", "useLedgerId"];

  function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function FormGlAutocomplete(props) {
    var autocompleteDefaults = (0, _autocomplete.useDefaults)();

    var formProps = props.formProps,
        renderInputParams = props.renderInputParams,
        useLedgerId = props.useLedgerId,
        restProps = _objectWithoutProperties(props, _excluded);

    var _useController = (0, _useForm.useController)({
      name: formProps.name,
      control: formProps.control
    }),
        field = _useController.field;

    var renderInput = restProps.renderInput || (0, _autocomplete.formAutocompleteRenderInput)(_objectSpread(_objectSpread({}, renderInputParams), {}, {
      control: formProps.control,
      onBlur: function onBlur(e) {
        var match = e.target.value !== null && e.target.value !== undefined ? restProps.options.find(function (itm) {
          return (restProps.getOptionLabel ? restProps.getOptionLabel(itm) : itm.label) === e.target.value || itm.ledgerId.toLowerCase() === e.target.value.toLowerCase();
        }) : null;

        if (useLedgerId) {
          formProps.control.setValue(formProps.name, match ? match.ledgerId : '');
        } else {
          formProps.control.setValue(formProps.name, match ? match : null);
        }

        formProps.control.setValue(renderInputParams.name, match ? restProps.getOptionLabel ? restProps.getOptionLabel(match) : match.label : '');
        field.onBlur();

        if (renderInputParams.onBlur) {
          renderInputParams.onBlur(e);
        }
      }
    }));
    (0, _react.useEffect)(function () {
      if (useLedgerId && restProps.options && restProps.options.length > 0) {
        var _formProps$control$ge, _formProps$control$ge2;

        var inputVal = (_formProps$control$ge = (_formProps$control$ge2 = formProps.control.getField(renderInputParams.name)) === null || _formProps$control$ge2 === void 0 ? void 0 : _formProps$control$ge2.value) !== null && _formProps$control$ge !== void 0 ? _formProps$control$ge : null;
        var match = inputVal !== null && inputVal !== undefined ? restProps.options.find(function (itm) {
          return (restProps.getOptionLabel ? restProps.getOptionLabel(itm) : itm.label) === inputVal || itm.ledgerId.toLowerCase() === inputVal.toLowerCase();
        }) : null;

        if (match) {
          formProps.control.setValue(renderInputParams.name, restProps.getOptionLabel ? restProps.getOptionLabel(match) : match.label);
        }
      }
    }, [restProps.options]);

    function _getOptionLabel(option) {
      if (useLedgerId) {
        if (option === "" || option === null || option === undefined) {
          return "";
        } else if (typeof option === "string") {
          var match = restProps.options.find(function (itm) {
            return (restProps.getOptionLabel ? restProps.getOptionLabel(itm) : itm.label) === option || itm.ledgerId.toLowerCase() === option.toLowerCase();
          });

          if (match) {
            return restProps.getOptionLabel ? restProps.getOptionLabel(match) : match.label;
          } else {
            return option;
          }
        } else {
          return option.label;
        }
      } else {
        return option.label;
      }
    }

    return React.createElement(_material.Autocomplete, _extends({}, autocompleteDefaults, {
      getOptionLabel: function getOptionLabel(x) {
        return _getOptionLabel(x);
      }
    }, restProps, {
      ref: field.ref,
      value: field.value,
      onChange: function onChange(e, v, r) {
        if (useLedgerId) {
          field.onChange(v ? v.ledgerId : '');
        } else {
          field.onChange(v);
        }

        formProps.control.setValue(renderInputParams.name, v ? restProps.getOptionLabel ? restProps.getOptionLabel(v) : v.label : '');

        if (props.onChange) {
          props.onChange(e, v, r);
        }
      },
      renderInput: renderInput
    }));
  }

  var _default = FormGlAutocomplete;
  _exports.default = _default;
});