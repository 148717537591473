define("client/components/wo-list/component", ["exports", "client/utils/set-overlaps"], function (_exports, _setOverlaps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  function overlap(node1, node2) {
    return node1.start < node2.start && node1.end > node2.start || node2.start < node1.start && node2.end > node1.start || node1.start === node2.start;
  }

  var _default = Ember.Component.extend({
    auth: inject.service(),
    utilities: inject.service(),
    classNames: ['relative'],
    overlaps: computed('workOrdersFiltered.@each.tech.content', 'workOrdersFiltered.@each.tech2.content', 'workOrdersFiltered.@each.timeAssigned', 'workOrders.@each.rows', function () {
      var arr = this.get('workOrdersFiltered') || Ember.A([]);
      arr = arr.sortBy('timeAssigned').map(function (x) {
        var start = moment.duration(x.get('timeAssigned')).asHours() * 2;
        return {
          start: start,
          end: start + x.get('rows'),
          wo: x
        };
      });
      var new_arr = [];
      var group_max = null;
      var levels_arr = [];
      var currLevel = 0;

      for (var j = 0; j < arr.length; j++) {
        var node = arr[j];

        if (currLevel == 0) {
          levels_arr.push(node.end);
          var obj = {
            level: currLevel,
            start: node.start,
            end: node.end,
            wo: node.wo
          };
          obj.local_max = obj; // you're always your own local max until told otherwise

          obj.group_max = obj; // at the start of a group,  you must be the group max

          group_max = obj;
          new_arr.push(obj);
          currLevel++;
        } else {
          for (var i = 0; i < levels_arr.length; i++) {
            if (levels_arr[i] <= node.start) {
              // must occupy the lowest available level
              currLevel = i;
              break;
            }
          } //whenever currLevel drops we need to look back and find the local max, can probably just check last entry to see if it overlaps


          var _obj = {
            level: currLevel,
            start: node.start,
            end: node.end,
            wo: node.wo
          };
          _obj.local_max = overlap(_obj, new_arr[new_arr.length - 1]) && new_arr[new_arr.length - 1].level > currLevel ? new_arr[new_arr.length - 1] : _obj;

          if (currLevel == 0) {
            // starting a new group
            group_max = _obj;
            levels_arr = [];
          } else {
            if (_obj.level > group_max.level) {
              var old_group_max = group_max;
              group_max = _obj; // we have a new group max, update everyone whose group_max used to be the old one

              for (var _i = 0; _i < new_arr.length; _i++) {
                if (old_group_max == new_arr[_i].group_max) {
                  new_arr[_i].group_max = group_max;
                }
              }
            } // now need to update all previous nodes that overlap this one with a new local max


            for (var _i2 = 0; _i2 < new_arr.length; _i2++) {
              if (overlap(new_arr[_i2], node) && currLevel > new_arr[_i2].level) {
                //we are the new local max for this node
                new_arr[_i2].local_max = _obj;
              }
            }
          }

          _obj.group_max = group_max;
          new_arr.push(_obj);
          levels_arr.push(node.end);
          currLevel++;
        }
      }

      return new_arr;
    }),
    actions: {
      invoiceWo: function invoiceWo(workOrder) {
        this.get('utilities').openInNewTab('srvc-billing', workOrder.get('id'));
      }
    }
  });

  _exports.default = _default;
});