define("client/react/components/alt/form-date-picker", ["exports", "@mui/material", "@mui/x-date-pickers-pro", "moment", "react", "client/react/utilities/use-form"], function (_exports, _material, _xDatePickersPro, _moment, _react, _useForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _excluded = ["formProps", "onChange", "required"];

  function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function FormDatePicker(_ref) {
    var formProps = _ref.formProps,
        _onChange = _ref.onChange,
        required = _ref.required,
        restProps = _objectWithoutProperties(_ref, _excluded);

    var controller = (0, _useForm.useController)({
      name: formProps.name,
      control: formProps.control
    });

    if (!controller) {
      return _react.default.createElement(_react.default.Fragment, null);
    }

    var field = controller.field,
        fieldState = controller.fieldState;
    return _react.default.createElement(_xDatePickersPro.DesktopDatePicker // below is required thanks to: https://github.com/mui/mui-x/issues/4664
    , _extends({
      disableMaskedInput: false,
      inputFormat: "MM/DD/YYYY",
      mask: '__/__/____',
      showToolbar: true,
      openTo: "day",
      OpenPickerButtonProps: {
        tabIndex: -1
      }
    }, restProps, {
      onChange: function onChange(date, value) {
        field.onChange(date);

        if (_onChange) {
          _onChange(date, value);
        }
      },
      renderInput: function renderInput(params) {
        var _fieldState$error;

        return _react.default.createElement(_material.TextField, _extends({}, params, {
          label: restProps.label,
          className: restProps.className,
          error: fieldState.invalid,
          helperText: (_fieldState$error = fieldState.error) === null || _fieldState$error === void 0 ? void 0 : _fieldState$error.message,
          size: restProps.size ? restProps.size : "medium",
          variant: "standard",
          margin: restProps.margin,
          required: required,
          onFocus: function onFocus(e) {
            if (restProps.onFocus) {
              restProps.onFocus(e);
            }
          },
          onBlur: function onBlur(e) {
            var inputFormat = restProps.inputFormat || "MM/DD/YYYY";
            var valAsMoment = e.target.value && e.target.value.length === inputFormat.length ? (0, _moment.default)(e.target.value, inputFormat) : null;

            if (restProps.onBlur) {
              restProps.onBlur(e, valAsMoment);
            }

            field.onBlur();
          }
        }));
      },
      value: field.value
    }));
  }

  var _default = FormDatePicker;
  _exports.default = _default;
});