define("client/react/custom-dashboards/utils", ["exports", "@progress/kendo-react-data-tools", "react", "react-fast-compare", "client/react/utilities/smart-form"], function (_exports, _kendoReactDataTools, _react, _reactFastCompare, _smartForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.aggregationFunctions = void 0;
  _exports.deserializeFilters = deserializeFilters;
  _exports.filterComponentsFromType = filterComponentsFromType;
  _exports.getFormatFunction = getFormatFunction;
  _exports.serializeFilters = serializeFilters;

  function filterComponentsFromType(type) {
    switch (type) {
      case 'string':
        return {
          filter: _kendoReactDataTools.TextFilter,
          operators: _kendoReactDataTools.Operators.text
        };

      case 'money':
        return {
          filter: _kendoReactDataTools.NumericFilter,
          operators: _kendoReactDataTools.Operators.numeric
        };

      case 'boolean':
        return {
          filter: _kendoReactDataTools.BooleanFilter,
          operators: _kendoReactDataTools.Operators.boolean
        };
    }
  } // Creates a kendoReact filters datastructure from Servisuite format


  function deserializeFilters(fieldsMap, filters) {
    if ((0, _reactFastCompare.default)(filters, {})) {
      return {
        logic: "and",
        filters: []
      };
    }

    switch (filters.type) {
      case 'and':
        return {
          logic: "and",
          filters: filters.children.map(function (x) {
            return deserializeFilters(fieldsMap, x);
          })
        };

      case 'or':
        return {
          logic: "or",
          filters: filters.children.map(function (x) {
            return deserializeFilters(fieldsMap, x);
          })
        };

      case 'condition':
        return {
          field: filters.field,
          operator: filters.operator,
          value: filters.value,
          aggregationFn: filters.aggregationFn
        };
    }
  }

  function serializeFilters(fieldsMap, filters) {
    if (filters.logic && !filters.filters.length) {
      return {};
    }

    switch (filters.logic) {
      case 'and':
        return {
          type: "and",
          children: filters.filters.map(function (x) {
            return serializeFilters(fieldsMap, x);
          }).filter(function (x) {
            return !!x && !(0, _reactFastCompare.default)(x, {});
          })
        };

      case 'or':
        return {
          type: "or",
          children: filters.filters.map(function (x) {
            return serializeFilters(fieldsMap, x);
          }).filter(function (x) {
            return !!x;
          })
        };

      default:
        if (!filters.field || !filters.operator) {
          return null;
        }

        return {
          type: 'condition',
          field: filters.field,
          operator: filters.operator,
          value: filters.value,
          filterType: !filters.aggregationFn && filters.field !== 'Count' ? "where" : fieldsMap[filters.field].filterType,
          aggregationFn: filters.aggregationFn
        };
    }
  }

  function getFormatFunction(type) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        isAxis = _ref.isAxis;

    switch (type) {
      case 'money':
        return function (val) {
          return parseFloat(val).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            notation: 'compact'
          });
        };

      case 'number':
        return function (val) {
          return parseInt(val);
        };

      case 'float':
        return function (val) {
          return parseFloat(val).toFixed(2);
        };

      case 'percent':
        return function (val) {
          return (parseFloat(val) / 100).toLocaleString('en-US', {
            style: 'percent',
            notation: 'compact',
            minimumFractionDigits: 2
          });
        };

      case 'boolean':
        return function (val) {
          return val;
        };

      case 'date':
        return function (val) {
          return moment(val).format('MM/DD/YYYY');
        };

      case 'monthYear':
        return function (val) {
          return moment(val, "YYYY-MM").format('MM/YYYY');
        };

      case 'year':
        return function (val) {
          return val;
        };

      default:
        return function (val) {
          return val;
        };
    }
  }

  var aggregationFunctions = ['SUM', 'AVG', 'MIN', 'MAX', 'ANY_VALUE'];
  _exports.aggregationFunctions = aggregationFunctions;
});