define("client/react/payroll/create-payroll-dialog", ["exports", "@mui/material", "react", "react-redux", "client/react/data/reducer", "client/react/utilities/use-form", "client/react/components/form-input", "client/react/components/alt/form-autocomplete", "yup", "client/react/payroll/payroll-create-progress-dialog", "@mui/styles", "client/react/utilities/styles", "client/react/components/spacer", "client/react/utilities/autocomplete", "client/react/components/alt/form-text-field", "client/react/utilities/smart-form"], function (_exports, _material, _react, _reactRedux, _reducer, _useForm, _formInput, _formAutocomplete, yup, _payrollCreateProgressDialog, _styles, _styles2, _spacer, _autocomplete, _formTextField, _smartForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var useStyles = (0, _styles.makeStyles)(function (theme) {
    return _objectSpread({}, _styles2.styles);
  });
  var taxFrequencies = [{
    name: 'Daily',
    value: 260
  }, {
    name: 'Weekly',
    value: 52
  }, {
    name: 'Biweekly',
    value: 26
  }, {
    name: 'Semimonthly',
    value: 24
  }, {
    name: 'Monthly',
    value: 12
  }, {
    name: 'Quarterly',
    value: 4
  }, {
    name: 'Semiannually',
    value: 2
  }];
  var schema = yup.object().shape({
    startDate: yup.date().required(),
    endDate: yup.date().when('startDate', {
      is: function is(val) {
        return !!val;
      },
      then: yup.date().min(yup.ref('startDate')).required(),
      otherwise: yup.date().required()
    }),
    checkDate: yup.date().required(),
    description: yup.string(),
    frequency: yup.object().nullable().required(),
    frequencyInput: yup.string(),
    payrollType: yup.object().nullable().required(),
    payrollTypeInput: yup.string()
  });

  function CreatePayrollDialog(_ref) {
    var onClose = _ref.onClose;
    var classes = useStyles();
    var autocompleteDefaults = (0, _autocomplete.useDefaults)();
    var payrollTypes = (0, _reactRedux.useSelector)(function (state) {
      return Object.values(state.data.payrollType.byId);
    });
    var dispatch = (0, _reactRedux.useDispatch)();

    var _useState = (0, _react.useState)(false),
        _useState2 = _slicedToArray(_useState, 2),
        loading = _useState2[0],
        setLoading = _useState2[1];

    var _useState3 = (0, _react.useState)(true),
        _useState4 = _slicedToArray(_useState3, 2),
        isOpen = _useState4[0],
        setIsOpen = _useState4[1];

    var _useSmartForm = (0, _smartForm.default)({
      schema: schema,
      defaultValues: {
        startDate: null,
        endDate: null,
        checkDate: null,
        description: '',
        frequency: taxFrequencies.find(function (itm) {
          return itm.value === 52;
        }),
        frequencyInput: taxFrequencies.find(function (itm) {
          return itm.value === 52;
        }).name,
        payrollType: payrollTypes[0] || null,
        payrollTypeInput: payrollTypes[0] ? payrollTypes[0].name : ''
      }
    }),
        handleSubmit = _useSmartForm.handleSubmit,
        control = _useSmartForm.control,
        errors = _useSmartForm.formState.errors,
        setValue = _useSmartForm.setValue;

    (0, _react.useEffect)(function () {
      dispatch((0, _reducer.fetchModels)('payrollType', {}, function (types) {
        setValue('payrollType', types[0] || null);
        setValue('payrollTypeInput', types[0] ? types[0].name : '');
        setValue('description', types[0] ? types[0].name : '');
      }));
    }, []);

    function savePayroll(data) {
      setLoading(true);
      dispatch((0, _reducer.createModel)('payroll', _objectSpread(_objectSpread({}, data), {}, {
        frequency: data.frequency.value,
        payrollType: data.payrollType.id
      }), function () {
        setIsOpen(false);
      }));
    }

    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_material.Dialog, {
      open: isOpen,
      TransitionProps: {
        onExited: function onExited() {
          return onClose();
        }
      }
    }, _react.default.createElement(_material.DialogTitle, null, "Create New Payroll"), _react.default.createElement(_material.DialogContent, {
      className: classes.column
    }, _react.default.createElement("div", {
      className: classes.row
    }, _react.default.createElement(_formTextField.default, {
      formProps: {
        name: 'startDate',
        control: control
      },
      hideHelperText: true,
      autoFocus: true,
      label: 'Start Date',
      required: true,
      type: 'date',
      InputLabelProps: {
        shrink: true
      }
    }), _react.default.createElement(_spacer.default, null), _react.default.createElement(_formTextField.default, {
      formProps: {
        name: 'endDate',
        control: control
      },
      hideHelperText: true,
      label: 'End Date',
      required: true,
      type: 'date',
      InputLabelProps: {
        shrink: true
      }
    }), _react.default.createElement(_spacer.default, null), _react.default.createElement(_formTextField.default, {
      formProps: {
        name: 'checkDate',
        control: control
      },
      hideHelperText: true,
      label: 'Check Date',
      required: true,
      type: 'date',
      InputLabelProps: {
        shrink: true
      }
    })), _react.default.createElement(_formAutocomplete.default, _extends({}, autocompleteDefaults, {
      formProps: {
        name: 'payrollType',
        control: control
      },
      onChange: function onChange(e, v, r) {
        setValue('payrollTypeInput', v ? v.name : '');
        setValue('description', v ? v.name : '');
      },
      options: payrollTypes,
      getOptionLabel: function getOptionLabel(x) {
        return "".concat(x.name);
      },
      renderInputParams: {
        name: 'payrollTypeInput',
        label: 'Payroll Type',
        error: !!errors.payrollType,
        required: true
      }
    })), _react.default.createElement(_formAutocomplete.default, _extends({}, autocompleteDefaults, {
      formProps: {
        name: 'frequency',
        control: control
      },
      onChange: function onChange(e, v, r) {
        setValue('frequencyInput', v ? v.name : '');
      },
      options: taxFrequencies,
      getOptionLabel: function getOptionLabel(x) {
        return "".concat(x.name);
      },
      renderInputParams: {
        name: 'frequencyInput',
        label: 'Frequency',
        error: !!errors.frequency,
        required: true
      }
    })), _react.default.createElement(_formInput.default, {
      control: control,
      name: "description",
      label: "Description"
    })), _react.default.createElement(_material.DialogActions, null, _react.default.createElement(_material.Button, {
      color: "inherit",
      disabled: loading,
      onClick: function onClick() {
        return setIsOpen(false);
      }
    }, "Close"), _react.default.createElement(_material.Button, {
      disabled: loading,
      color: 'primary',
      onClick: function onClick() {
        return handleSubmit(savePayroll)();
      }
    }, loading ? _react.default.createElement(_material.CircularProgress, null) : "Save"))), loading && _react.default.createElement(_payrollCreateProgressDialog.default, null));
  }

  var _default = CreatePayrollDialog;
  _exports.default = _default;
});