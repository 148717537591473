define("client/react/store", ["exports", "@reduxjs/toolkit", "client/react/auth/reducer", "client/react/payroll/reducer", "client/react/data/reducer", "client/react/custom-dashboards/reducer", "@manaflair/redux-batch"], function (_exports, _toolkit, _reducer, _reducer2, _reducer3, _reducer4, _reduxBatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.store = void 0;
  var initialState = Object.assign({}, _reducer.authInitialState, _reducer3.dataInitialState, _reducer2.payrollInitialState, _reducer4.initialState);

  var mergeReducers = function mergeReducers() {
    for (var _len = arguments.length, reducers = new Array(_len), _key = 0; _key < _len; _key++) {
      reducers[_key] = arguments[_key];
    }

    return function (state, action) {
      var old = Object.assign({}, state || initialState);
      return reducers.reduce(function (accumulatedState, reducer) {
        var output = reducer(accumulatedState, action);
        return Object.assign({}, accumulatedState, output);
      }, old);
    };
  };

  var store = (0, _toolkit.configureStore)({
    reducer: mergeReducers(_reducer.authReducer, _reducer3.dataReducer, _reducer2.payrollReducer, _reducer4.default),
    enhancers: [_reduxBatch.reduxBatch]
  });
  _exports.store = store;
  store.subscribe(function () {
    console.log('subscriber fired');
  });
});