define("client/react/utilities/helpers", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCurrency = formatCurrency;
  _exports.formatDate = formatDate;
  _exports.formatHumanFriendlyDateTime = formatHumanFriendlyDateTime;
  _exports.formatPhoneNumber = formatPhoneNumber;
  _exports.formatTime = formatTime;
  _exports.getAlternativeLaborRateLabel = getAlternativeLaborRateLabel;
  _exports.getBestPhone = getBestPhone;
  _exports.getCallDurationLabel = getCallDurationLabel;
  _exports.getContractLabel = getContractLabel;
  _exports.getContractTypeLabel = getContractTypeLabel;
  _exports.getCostCenterLabel = getCostCenterLabel;
  _exports.getCustomerLabel = getCustomerLabel;
  _exports.getEmployeeLabel = getEmployeeLabel;
  _exports.getEquipmentLabel = getEquipmentLabel;
  _exports.getEquipmentSerial = getEquipmentSerial;
  _exports.getJobLabel = getJobLabel;
  _exports.getLeadLabel = getLeadLabel;
  _exports.getLedgerAccountLabel = getLedgerAccountLabel;
  _exports.getSalesTaxJurisdictionLabel = getSalesTaxJurisdictionLabel;
  _exports.getSiteLabel = getSiteLabel;
  _exports.getStatusLabel = getStatusLabel;
  _exports.getTechLabel = getTechLabel;
  _exports.getWarehouseLabel = getWarehouseLabel;
  _exports.moneyHelper = moneyHelper;
  _exports.stringToDate = stringToDate;

  function formatTime(time) {
    if (time) {
      var hour = parseInt(time.substr(0, 2));
      var minute = parseInt(time.substr(time.length === 5 ? 3 : 2, 2));
      var isPm = hour > 12;
      return "".concat(isPm ? hour - 12 : hour, ":").concat(minute, " ").concat(isPm ? "PM" : "AM");
    } else {
      return "";
    }
  }

  function moneyHelper(value, blankOnFalse) {
    if (!value) {
      return blankOnFalse ? "" : "$0.00";
    } else {
      return "$" + parseFloat(value).toFixed(2);
    }
  }

  function formatDate(date) {
    return date ? (0, _moment.default)(date).format("MM/DD/YYYY") : "";
  }

  function formatPhoneNumber(str) {
    //Filter only numbers from the input
    var cleaned = ("" + str).replace(/\D/g, ""); //Check if the input is of correct length

    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return null;
  }

  function formatCurrency(val) {
    var valToUse = isNaN(parseFloat(val)) ? 0 : parseFloat(val);
    return valToUse.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    });
  }

  function getBestPhone(custOrSite) {
    if (custOrSite) {
      if (custOrSite.homePhone) return custOrSite.homePhone;else if (custOrSite.cellPhone) return custOrSite.cellPhone;else if (custOrSite.altPhone) return custOrSite.altPhone;else return "";
    } else {
      return "";
    }
  }

  function getCostCenterLabel(costCenter) {
    return costCenter ? "".concat(costCenter.id < 10 ? "0".concat(costCenter.id) : "".concat(costCenter.id.toString()), " - ").concat(costCenter.description) : "";
  }

  function getStatusLabel(status) {
    return status ? "".concat(status.description) : "";
  }

  function getTechLabel(tech) {
    return tech ? "".concat(tech.employeeNumber, " - ").concat(tech.name) : "";
  }

  function getContractLabel(contract) {
    return contract ? "".concat(contract.contractNumber, " - ").concat(contract.typeDescription) : "";
  }

  function getCustomerLabel(customer) {
    return customer ? "".concat(customer.custId, " - ").concat(customer.billingName) : '';
  }

  function getSiteLabel(site) {
    return site ? "".concat(site.shipId, " - ").concat(site.siteName) : '';
  }

  function getContractTypeLabel(type) {
    return type ? "".concat(type.code, " - ").concat(type.desc) : '';
  }

  function getCallDurationLabel(duration) {
    return duration ? "".concat(duration.label) : "";
  }

  function getWarehouseLabel(warehouse) {
    return warehouse ? "".concat(warehouse.number, " - ").concat(warehouse.name) : "";
  }

  function getSalesTaxJurisdictionLabel(taxJurisdiction) {
    return taxJurisdiction ? "".concat(taxJurisdiction.id, " - ").concat(taxJurisdiction.jurisdiction, " - ").concat(taxJurisdiction.rate, "%") : "";
  }

  function getLeadLabel(lead) {
    var name = lead ? lead.siteName ? lead.siteName : lead.custName : "";
    return lead ? "".concat(lead.id, " - ").concat(name, " - ").concat(lead.stage) : "";
  }

  function getJobLabel(job) {
    return job ? "".concat(job.jobId, " - ").concat(job.projectName) : "";
  }

  function getEquipmentSerial(equipment) {
    return equipment ? equipment.serial : "";
  }

  function getEquipmentLabel(equipment) {
    return equipment ? "".concat(equipment.model, " - ").concat(equipment.serial, " - ").concat(equipment.manufacturer) : "";
  }

  function getAlternativeLaborRateLabel(laborRate) {
    if (laborRate && laborRate.rate === 0) {
      return laborRate.description;
    } else {
      return laborRate ? "".concat(laborRate.description, " - ").concat(parseFloat(laborRate.rate).toFixed(2)) : "";
    }
  }

  function getLedgerAccountLabel(ledgerAccount) {
    return ledgerAccount ? ledgerAccount.label : "";
  }

  function getEmployeeLabel(emp) {
    return emp ? "".concat(emp.legacyEmployeeId, " - ").concat(emp.lastName, ",").concat(emp.firstName) : "";
  }

  function stringToDate(string) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        asUTC = _ref.asUTC;

    // YYYY-MM-DD format only supported currently
    if (string) {
      var year = parseInt(string.substr(0, 4));
      var month = parseInt(string.substr(5, 7)) - 1; // month is only param zero-indexed

      var day = parseInt(string.substr(8, 10));
      return asUTC ? new Date(Date.UTC(year, month, day)) : new Date(year, month, day);
    } else {
      return null;
    }
  }

  function formatHumanFriendlyDateTime(time) {
    return time ? _moment.default.utc(time).local().format('MMM Do, YYYY, h:mm A') : "../../..";
  }
});