define("client/react/payroll/create-statement-dialog", ["exports", "@mui/material", "react", "react-redux", "react-router", "client/react/data/reducer", "client/react/utilities/snackbar", "clsx", "@mui/styles", "client/react/utilities/styles"], function (_exports, _material, _react, _reactRedux, _reactRouter, _reducer, _snackbar, _clsx, _styles, _styles2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  // import { sortBy } from "../utils";
  var useStyles = (0, _styles.makeStyles)(function (theme) {
    return _objectSpread({}, _styles2.styles);
  });

  function CreateStatementDialog(_ref) {
    var onClose = _ref.onClose;
    var classes = useStyles();
    var dispatch = (0, _reactRedux.useDispatch)();
    var history = (0, _reactRouter.useHistory)();
    var store = (0, _reactRedux.useStore)();

    var _useState = (0, _react.useState)(true),
        _useState2 = _slicedToArray(_useState, 2),
        isOpen = _useState2[0],
        setIsOpen = _useState2[1];

    var _useState3 = (0, _react.useState)(false),
        _useState4 = _slicedToArray(_useState3, 2),
        isLoading = _useState4[0],
        setIsLoading = _useState4[1];

    var _useState5 = (0, _react.useState)(""),
        _useState6 = _slicedToArray(_useState5, 2),
        notes = _useState6[0],
        setNotes = _useState6[1];

    var _useState7 = (0, _react.useState)(null),
        _useState8 = _slicedToArray(_useState7, 2),
        employee = _useState8[0],
        setEmployee = _useState8[1];

    var employees = (0, _reactRedux.useSelector)(function (state) {
      return Object.values(state.data.employee.byId).filter(function (emp) {
        return !!emp.active;
      });
    });
    var payrollStatementsByEmployeeId = (0, _reactRedux.useSelector)(function (state) {
      return Object.values(state.data.payrollStatement.byId).reduce(function (p, c) {
        if (c) {
          p[c.employee] = c;
        }

        return p;
      }, {});
    }); // We want to make sure that the createStatement function updates anytime the selected payroll does.

    var selectedPayroll = (0, _reactRedux.useSelector)(function (state) {
      return state.payroll.selectedPayroll;
    }); // fetch employees

    (0, _react.useEffect)(function () {
      dispatch((0, _reducer.fetchModels)('employee', {
        activeOnly: true
      }));
    }, []);

    function createStatement() {
      if (!!payrollStatementsByEmployeeId[employee && employee.id] && payrollStatementsByEmployeeId[employee && employee.id].payroll === (selectedPayroll === null || selectedPayroll === void 0 ? void 0 : selectedPayroll.id)) {
        (0, _snackbar.showSnackbar)("There is already a statement for this employee", {
          variant: 'error'
        });
      } else if (!employee) {
        (0, _snackbar.showSnackbar)("Please select an employee", {
          variant: 'error'
        });
      } else {
        setIsLoading(true);
        dispatch((0, _reducer.createModel)('payrollStatement', {
          payroll: store.getState().payroll.selectedPayroll.id,
          employee: employee.id,
          notes: notes,
          netPay: 0,
          grossPay: 0,
          directDeposit: employee.directDeposit,
          reimbursement: 0
        }, function (statement) {
          history.push("/pr-compute-payroll/".concat(statement.id));
          setIsOpen(false);
        }));
      }
    }

    return React.createElement(_material.Dialog, {
      open: isOpen,
      TransitionProps: {
        onExited: function onExited() {
          return onClose();
        }
      }
    }, React.createElement(_material.DialogTitle, null, "New Payroll Statement"), isLoading ? React.createElement(_material.DialogContent, {
      className: (0, _clsx.default)(classes.column, classes.alignCenterCenter)
    }, React.createElement(_material.CircularProgress, null)) : React.createElement(_material.DialogContent, null, React.createElement(_material.Autocomplete, {
      value: employee,
      onChange: function onChange(e, value) {
        return setEmployee(value);
      },
      options: employees.sort(function (a, b) {
        return a.lastName.localeCompare(b.lastName);
      }),
      getOptionLabel: function getOptionLabel(option) {
        return option.name;
      },
      style: {
        width: 300
      },
      renderInput: function renderInput(params) {
        return React.createElement(_material.TextField, _extends({}, params, {
          label: "Employee"
        }));
      }
    }), React.createElement(_material.TextField, {
      fullWidth: true,
      value: notes,
      onChange: function onChange(e) {
        return setNotes(e.target.value);
      },
      label: "Notes"
    })), React.createElement(_material.DialogActions, null, React.createElement(_material.Button, {
      color: "inherit",
      disabled: isLoading,
      onClick: function onClick(e) {
        return setIsOpen(false);
      }
    }, "Close"), React.createElement(_material.Button, {
      disabled: isLoading,
      color: 'primary',
      onClick: function onClick(e) {
        return createStatement();
      }
    }, "Create")));
  }

  var _default = CreateStatementDialog;
  _exports.default = _default;
});