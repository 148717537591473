define("client/components/report-param/component", ["exports", "client/config/environment", "ember-concurrency", "client/utils/citron-date"], function (_exports, _environment, _emberConcurrency, _citronDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    auth: inject.service(),
    utilities: inject.service(),
    ctypes: null,
    currentPeriod: moment().format('YYYY-MM'),
    classNames: ['layout-row', 'flex'],
    //	classNameBindings: ['isSelect:layout-row', 'isSelect:flex'],
    sortParamsJobType: ['key'],
    sortParamsJob: ['jobId'],
    sortParamsEmployee: ['employeeId'],
    sortParamsVendor: ['vendorId'],
    sortParamsLedger: ['ledgerId'],
    sortParamsCategory: ['catId'],
    sortParamsContract: ['contractId'],
    sortedCategories: computed.sort('categories', 'sortParamsCategory'),
    sortedJobs: computed.sort('jobs', 'sortParamsJob'),
    sortedLedgers: computed.sort('ledgers', 'sortParamsLedger'),
    sortedJobTypes: computed.sort('jobTypes', 'sortParamsJobType'),
    sortedEmployees: computed.sort('employees', 'sortParamsEmployee'),
    sortedVendors: computed.sort('vendors', 'sortParamsVendor'),
    store: inject.service(),
    quarterValidation: [{
      message: 'Quarter End Date can only be 3/31, 6/30, 9/30, 12/31',
      validate: function validate(inputValue) {
        var quarterPattern = /[0-9]+\-(03-31|06-30|09-30|12-31)/;
        return quarterPattern.test(inputValue);
      }
    }],
    isEmpty: Ember.computed('field.type', function () {
      return this.get('field.type') == "empty";
    }),
    isMmyy: Ember.computed('field.type', function () {
      return this.get('field.type') == "mmyy" && this.get('field.name') !== "Cutoff Period" && this.get('field.name') !== "Start Period" && this.get('field.name') !== "End Period";
    }),
    isServiceMonth: Ember.computed('field.type', function () {
      return this.get('field.type') == "serviceMonth";
    }),
    isContract: Ember.computed('field.type', function () {
      return this.get('field.type') == "contract";
    }),
    isContractTypes: Ember.computed('field.type', function () {
      return this.get('field.type') == "contractTypes";
    }),
    isProduct: Ember.computed('field.type', function () {
      return this.get('field.type') == "product";
    }),
    isTech: Ember.computed('field.type', function () {
      return this.get('field.type') == "tech";
    }),
    isLpadNumber: Ember.computed('field.type', function () {
      return this.get('field.type') == "lpadNumber" && this.get('field.name') !== "Cost Center";
    }),
    isCostCenter: Ember.computed('field.type', function () {
      return this.get('field.name') === "Cost Center";
    }),
    isShipTo: Ember.computed('field.type', function () {
      return this.get('field.type') == "shipTo";
    }),
    isCheck: Ember.computed('field.type', function () {
      return this.get('field.type') == "check" && this.get('field.name') !== "Reset File";
    }),
    isQtrEnd: Ember.computed('field.type', function () {
      return false; //this.get('field.name') == "QtrEndDate";
    }),
    isJob: computed('field.type', function () {
      return this.get('field.type') == "job";
    }),
    isLedger: computed('field.type', function () {
      return this.get('field.type') == "ledger";
    }),
    isCustId: computed('field.type', function () {
      return this.get('field.type') == "custId";
    }),
    isCustName: computed('field.type', function () {
      return this.get('field.type') == "custName";
    }),
    isJobType: computed('field.type', function () {
      return this.get('field.type') == "job-type";
    }),
    isEmployee: computed('field.type', function () {
      return this.get('field.type') == "employee";
    }),
    isVendor: computed('field.type', function () {
      return this.get('field.type') == "vendor";
    }),
    isCategory: computed('field.type', function () {
      return this.get('field.type') == "category";
    }),
    isProductCategory: computed('field.type', function () {
      return this.get('field.type') == "productCategory";
    }),
    isCash: computed('field.type', function () {
      return this.get('field.type') == "cash";
    }),
    isFinalGl: computed('field.name', function () {
      return this.get('field.name') === "FinalGlFlag";
    }),
    isFinalGl2: computed('field.name', function () {
      return this.get('field.name') === "FinalGlFlag2";
    }),
    isReset: computed('field.name', function () {
      return this.get('field.name') == "Reset File";
    }),
    isBalanceForward: computed('field.name', function () {
      return this.get('field.name') == "Balance Forward Period";
    }),
    isCutoffPeriod: computed('field.name', function () {
      return this.get('field.name') == "Cutoff Period";
    }),
    isStartPeriod: computed('field.name', function () {
      return this.get('field.name') == "Start Period";
    }),
    isEndPeriod: computed('field.name', function () {
      return this.get('field.name') == "End Period";
    }),
    isText: Ember.computed('field.type', function () {
      if (this.get('field.name') == "Balance Forward Period" || this.get('field.name') == "Cutoff Period" || this.get('field.name') == "QtrEndDate" || this.get('field.name') == "period" || this.get('field.name') == "FinalGlFlag" || this.get('field.name') == "FinalGlFlag2") {
        return false;
      }

      return this.get('field.type') == "text" || this.get('field.type') == "printer" || [].contains(this.get('field.type'));
    }),
    isReportPeriod: Ember.computed('field.name', function () {
      return this.get('field.name') == "Report Period";
    }),
    isPeriod: Ember.computed('field.type', function () {
      if (this.get('field.type') == "text" && this.get('field.name') == "period") {
        return true;
      }
    }),
    isSelect: Ember.computed('field.type', function () {
      return this.get('field.type') == "select";
    }),
    isSubrecord: Ember.computed('field.type', function () {
      return this.get('field.type') == "subrecord";
    }),
    isDate: Ember.computed('field.type', function () {
      return this.get('field.type') == "date";
    }),
    isDateYearFirst: Ember.computed('field.type', function () {
      return this.get('field.type') == "dateYearFirst";
    }),
    isWorkOrderStatus: Ember.computed('field.type', function () {
      return this.get('field.type') == "workOrderStatus";
    }),
    fieldLabel: Ember.computed('field.value', function () {
      var index = this.get('optionValues').indexOf(this.get('field.value'));
      return this.get('options').objectAt(index);
    }),
    options: Ember.computed.alias('field.options'),
    optionValues: Ember.computed.alias('field.optionValues'),
    label: Ember.computed.alias('field.label'),
    computeFinalGl: function computeFinalGl() {
      var today = moment();
      var date = today.date();

      if (date <= 24) {
        today.subtract('months', 1);
      }

      var ret = today.format('MM') + (0, _citronDate.default)(today.format('YYYY-MM-DD')).substring(4);
      return ret;
    },
    computeFinalGl2: function computeFinalGl2() {
      var today = moment();
      var date = today.date();

      if (date <= 24) {
        today.subtract('months', 1);
      }

      var ret = today.format('YYMM');
      return (0, _citronDate.default)(today.format('YYYY-MM-DD')).substring(4) + today.format('MM');
    },
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('field.default')) {
        this.set('field.value', this.get('isCheck') ? 'Y' : this.get('field.default'));
      }

      if (this.get('field.name') == "Report Date") {
        this.send('setDate', moment().format('YYYY-MM-DD'));
      }

      if (this.get('isPeriod')) {
        this.get('setPriorPeriod').perform();
      }

      if (this.get('isFinalGl')) {
        this.set('field.value', this.computeFinalGl());
      }

      if (this.get('isFinalGl2')) {
        this.set('field.value', this.computeFinalGl2());
      }

      if (this.get('isReportPeriod')) {
        this.set('field.value', moment().format('M'));
      }

      if (this.get('field.name') == 'Report Year') {
        this.set('field.value', moment().format('YY'));
      }

      this.set('ctypes', Ember.A([]));
    },
    actions: {
      setProdId: function setProdId(field, value) {
        field.set('value', value.get('prodId'));
        var fields = this.get('fields');
        fields.forEach(function (x) {
          if (x.get('name') == 'Reset File') {
            x.set('value', 'N');
          }
        });
      },
      setMmyy: function setMmyy(value) {
        this.set('field.value', value ? value.split('/').join('') : '');
        var fields = this.get('fields');
        fields.forEach(function (x) {
          if (x.get('name') == 'Reset File') {
            x.set('value', 'N');
          }
        });
      },
      setYymm: function setYymm(value) {
        this.set('field.value', value ? value.split('/').reverse().join('') : '');
        var fields = this.get('fields');
        fields.forEach(function (x) {
          if (x.get('name') == 'Reset File') {
            x.set('value', 'N');
          }
        });
      },
      addType: function addType(type) {
        this.get('ctypes').addObject(type.toUpperCase());
        this.set('field.value', this.get('ctypes').toArray().join(""));
        var fields = this.get('fields');
        fields.forEach(function (x) {
          if (x.get('name') == 'Reset File') {
            x.set('value', 'N');
          }
        });
      },
      removeType: function removeType(type) {
        this.get('ctypes').removeObject(type);
        this.set('field.value', this.get('ctypes').toArray().join());
      },
      setServiceMonth: function setServiceMonth(field, date2) {
        var date = moment(date2, 'MM/YY');
        var decadeArray = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
        var decadeDigit = date.format('YYYY-MM-DD').substr(2, 1);
        var decadeLetter = decadeArray[decadeDigit];
        var year = date.format('MMDDYY');
        var ret = year.substring(0, 4) + decadeLetter + year.substr(5);
        field.set('value', ret);
        var fields = this.get('fields');
        fields.forEach(function (x) {
          if (x.get('name') == 'Reset File') {
            x.set('value', 'N');
          }
        });
      },
      serializeChecked: function serializeChecked(value) {
        this.set('field.value', value ? 'Y' : 'N');
        var fields = this.get('fields');

        if (this.get('field.name') !== "Print GL Detail") {
          fields.forEach(function (x) {
            if (x.get('name') == 'Reset File') {
              x.set('value', 'N');
            }
          });
        }
      },
      serializeCheckedReset: function serializeCheckedReset(value) {
        this.set('field.value', value ? 'Y' : 'N');
        var fields = this.get('fields');
        fields.forEach(function (x) {
          if (x.get('name') !== 'Reset File' && x.get('name') !== 'Journal Type' && x.get('name') !== "Print GL Detail") {
            var defaultValue = x.get('default');
            x.set('value', defaultValue || null);
          }
        });
      },
      setDate: function setDate(value) {
        //			let decadeArray = ["A", "B", "C", "D", "E", "F", "G", "H", "I","J"];
        //			let date = moment(value,'YYYY-MM-DD');
        //
        //			let year = date.format('MMDDYY');
        //			let decadeDigit = date.format('YYYY-MM-DD').substr(2,1);
        //			let decadeLetter = decadeArray[decadeDigit];
        //			let ret = year.substr(0,4) + decadeLetter + year.substr(5);
        this.set('field.value', value);
        var fields = this.get('fields');
        fields.forEach(function (x) {
          if (x.get('name') == 'Reset File') {
            x.set('value', 'N');
          }
        });
      },
      setReverseDate: function setReverseDate(value) {
        this.set('field.value', value);
        var fields = this.get('fields');
        fields.forEach(function (x) {
          if (x.get('name') == 'Reset File') {
            x.set('value', 'N');
          }
        });
      },
      serializeText: function serializeText(value) {
        this.set('field.value', value);
        var fields = this.get('fields');
        fields.forEach(function (x) {
          if (x.get('name') == 'Reset File') {
            x.set('value', 'N');
          }
        });
      },
      serializeSelect: function serializeSelect(value) {
        var index = this.get('options').indexOf(value);
        this.set('field.value', this.get('optionValues').objectAt(index));
        var fields = this.get('fields');
        fields.forEach(function (x) {
          if (x.get('name') == 'Reset File') {
            x.set('value', 'N');
          }
        });
      },
      onCustomerChange: function onCustomerChange(customer) {
        this.sendAction('onCustomerChange', customer);
        var fields = this.get('fields');
        fields.forEach(function (x) {
          if (x.get('name') == 'Reset File') {
            x.set('value', 'N');
          }
        });
      },
      selectCostCenter: function selectCostCenter(obj) {
        if (obj) {
          this.set('field.value', obj.get('costCenter'));
          this.set('costCenter', obj);
        } else {
          this.set('field.value', '');
          this.set('costCenter', null);
        }
      },
      validateCostCenter: function validateCostCenter() {
        var costCenters = this.get('utilities.costCenters');
        if (!costCenters) return;
        var searchText = this.get('field.value');
        var match = costCenters.findBy('costCenter', searchText);

        if (match) {
          this.send('selectCostCenter', match);
        } else {
          this.send('selectCostCenter', null);
        }
      },
      setStartPeriod: function setStartPeriod(date) {
        var fields = this.get('fields');
        var startField = null;
        var cutoffField = null;
        var startYearField = null;
        var cutoffFieldYear = null;
        fields.forEach(function (field) {
          if (field.get('name') === 'Balance Forward Hack Period') {
            startField = field;
          } else if (field.get('name') === "Balance Forward Hack Year") {
            startYearField = field;
          } else if (field.get('name') === "Cutoff Hack Period") {
            cutoffField = field;
          } else if (field.get('name') === "Cutoff Hack Year") {
            cutoffFieldYear = field;
          }
        });
        var m = moment(date, 'YYYY-MM');
        var year = m.format('YY');
        var period = m.format('MM');

        if (startField && startYearField) {
          startField.set('value', period);
          startYearField.set('value', year);
          this.set('field.value', date);
        } else {
          startField.set('value', year + period);
          this.set('field.value', date);
        }
      },
      setEndPeriod: function setEndPeriod(date) {
        var fields = this.get('fields');
        var startField = null;
        var cutoffField = null;
        var startYearField = null;
        var cutoffFieldYear = null;
        fields.forEach(function (field) {
          if (field.get('name') === 'Balance Forward Hack Period') {
            startField = field;
          } else if (field.get('name') === "Balance Forward Hack Year") {
            startYearField = field;
          } else if (field.get('name') === "Cutoff Hack Period") {
            cutoffField = field;
          } else if (field.get('name') === "Cutoff Hack Year") {
            cutoffFieldYear = field;
          }
        });
        var m = moment(date, 'YYYY-MM');
        var year = m.format('YY');
        var period = m.format('MM');

        if (startField && startYearField) {
          startField.set('value', period);
          startYearField.set('value', year);
          this.set('field.value', date);
        } else {
          cutoffField.set('value', year + period);
          this.set('field.value', date);
        }
      },
      setCutoffPeriod: function setCutoffPeriod(date) {
        var fields = this.get('fields');
        var startField = null;
        var cutoffField = null;
        var startYearField = null;
        var cutoffFieldYear = null;
        fields.forEach(function (field) {
          if (field.get('name') === 'Balance Forward Hack Period') {
            startField = field;
          } else if (field.get('name') === "Balance Forward Hack Year") {
            startYearField = field;
          } else if (field.get('name') === "Cutoff Hack Period") {
            cutoffField = field;
          } else if (field.get('name') === "Cutoff Hack Year") {
            cutoffFieldYear = field;
          }
        });
        var m = moment(date, 'YYYY-MM');
        var year = m.format('YY');
        var period = m.format('MM');
        cutoffField.set('value', period);
        cutoffFieldYear.set('value', year);
        this.set('field.value', date);
      },
      jobBlur: function jobBlur() {
        if (this.get('field.name') === "Start Job ID") {
          var endjob = this.get('fields').findBy('name', 'End Job ID');
          endjob && endjob.set('value', this.get('field.value'));
        }
      },
      selectStatus: function selectStatus(s) {
        if (s) {
          this.set('field.value', s.get('id'));
        } else {
          this.set('field.value', null);
        }
      },
      validateStatus: function validateStatus(searchText) {
        var statuses = this.get('utilities.statuses');
        if (!statuses) return;
        searchText = searchText ? searchText.trim() : '';
        var match = statuses.findBy('description', searchText);

        if (match) {
          this.send('selectStatus', match);
        }
      }
    }
  });

  _exports.default = _default;
});